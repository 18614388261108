@import "../../../variables/variables.scss";

.menu-container {
  background-color: $menu-bg-color;
  display: flex;
  flex-flow: row;
  align-items: flex-start;
}

/* Sidebar del menu */

.menu-sidebar {
  width: 280px;
}

.menu-logo {
  /* width: 180px; */
  margin-top: 18px;
  margin-bottom: 50px;
}

.menu-option {
  height: 60px;
  color: $menu_text_color;
  text-align: left;
  display: flex;
  flex-flow: row;
  align-items: center;
  transition: all 300ms;
}

.menu-option:hover {
  background-color: $menu_hover_option_color;
  cursor: pointer;
}

.menu-option img {
  width: 25px;
  margin: 0px 18px;
}

.menu-option h6 {
  font-weight: 700;
  font-size: 16px;
  margin: 0px;
}

.menu-dashboard {
  background-color: $dasboard_bg_color;
  width: 100%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  box-shadow: -5px 0px 5px $menu_shadow_color;
  min-height: 100vh;
  overflow: hidden;
}

/* Menu Header */

.menu-header {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px 30px 20px 30px;
  margin-bottom: 36px;
}

.menu-header h1 {
  margin: 0px;
  text-align: left;
}

.menu-header > div {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-evenly;
  color: $text_color;
  z-index: 3;
}

.menu-header div h6 {
  margin: 0px;
}

.menu-header div img {
  margin-left: 12px;
}

.menu-header-bar {
  height: 20px;
  width: 2px;
  background-color: $text_color;
  margin: 0px 10px;
}

/* Register */

.register-img-container {
  position: relative;
  margin-bottom: 24px;
  background-color: $register_bg_color;
}

.register-img-container h6 {
  font-weight: 700;
  color: $register_text_color;
  position: absolute;
  bottom: 12px;
  left: 12px;
  margin: 0px;
  font-size: 25px;
}

.register-img-container img {
  width: 100%;
  opacity: 0.9;
}

.register-input-container {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  text-align: left;
  margin-bottom: 12px;
}

.input-eye-container {
  width: 100%;
  position: relative;
}

.input-eye-container i {
  position: absolute;
  right: 12px;
  top: 23%;
}

.input-eye-container i:hover {
  cursor: pointer;
}

.call-to-action-link {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.call-to-action-link h6 {
  margin: 0px 12px;
  text-align: center;
  outline: none;
}

.save-button-contanier {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  /* width: 100%; */
}

/* Password Change */

/* Listado de Usuarios */

.userlist-search-container {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.userlist-search-container > div {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}

.userlist-search-container input {
  margin-right: 12px;
}

.call-to-action-back {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.empty-lists {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  color: $menu-bg-color;
  font-weight: 950;
  font-size: 25px;
  margin-top: 10%;
  font-family: $font;
}

#create-new-user-button {
  margin-right: 6px;
}

#save-button {
  margin-top: 20px;
}

/* Update Password */
.font-eye {
  border: none;
  color: $menu-bg-color;
  background: none;
}
