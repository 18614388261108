@import "../../../variables/variables.scss";

.load-spinner-container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 10000;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.load-spinner-container h4 {
  margin-top: 12px;
  margin-bottom: 48px;
  color: $load_message_text_color;
}
