@import "../../variables/variables.scss";

.login-container {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../../images//login-background.png");
  background-color: $login_bg_color;
  display: flex;
  flex-flow: row;
}

.login-dashboard {
  width: 500px;
  height: 100vh;
  padding: 20px;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  background-color: $login_dashboard_bg_color;
  box-shadow: rgba(0, 0, 0, 0.4) 2px 0px 3px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}

.login-dashboard img {
  width: 220px;
  /* height: 112px; */
  /* margin-bottom: 70px; */
}

#login-title {
  padding: 0px 20px;
}

.title {
  font-weight: 700;
  color: $text_color;
  font-size: 20px;
  text-align: left;
}

.login-line {
  width: 100%;
  height: 2px;
  background-color: $text_color;
  margin-bottom: 40px;
}

/* Inputs formularios */

.login-ident-content {
  width: 350px;
  padding-right: 50px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  /* border: 1px solid blue; */
}

.input-title {
  text-align: left;
  font-family: $font;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: $text_color;
}

.input {
  border-radius: 5px;
  border: 2px solid $input_border_color;
  width: 100%;
  height: 40px;
  padding-left: 12px;
  padding-bottom: 0px;
  font-weight: 700;
  color: $input_text_color;
  font-family: "Roboto Condensed", sans-serif;
}

select {
  border-radius: 5px;
  border: 2px solid $input_border_color;
  width: 100%;
  height: 40px;
  padding-left: 12px;
  padding-bottom: 0px;
  font-weight: 700;
  color: $input_text_color;
  font-family: "Roboto Condensed", sans-serif;
}

::placeholder {
  color: $place_holder_text_color;
  font-size: 14px;
}

.login-copyright {
  color: $place_holder_text_color;
  font-size: 12px;
}

/* Recordar Contraseña */
.rememeber-password {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin: 12px 0px;
}

.rememeber-password div {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px gray solid;
  box-shadow: 2px $place_holder_text_color;
  margin-right: 12px;
}

.rememeber-password h6 {
  color: $remember_password_color;
  font-size: 14px;
  margin: 0px;
}

/* Olvide Contraseña */

.forgot-password {
  font-size: 12px;
  margin-top: 12px;
  text-decoration: underline;
}

/* Button */

.button {
  border-radius: 6px;
  padding: 0px 28px;
  height: 40px;
  min-width: 120px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  transition: all 300ms;
}

.login-button:hover {
  cursor: pointer;
  border: 2px solid $login_button_bg_hover_color;
  background-color: $login_button_bg_hover_color;
}

.button h6 {
  font-weight: 700;
  font-size: 14px;
  margin: 0px;
}

.login-button {
  background-color: $login_button_bg_color;
  border: 2px solid $login_button_bg_color;
  color: $login_button_text_color;
}

.blocked-button {
  border: 2px solid !important;
  background-color: $blocked_button_bg_color;
}

.blocked-button:hover {
  cursor: context-menu $blocked_button_bg_color !important;
  background-color: $blocked_button_bg_color;
}

/* Slogan */

#login-slogan-container {
  height: 100vh;
}

#login-slogan-container h1 {
  width: 450px;
  bottom: 60px;
  left: 50px;
}

.slogan-container {
  width: auto;
  color: $menu_text_color;
  text-align: left;
  display: flex;
  align-items: flex-end;
  // overflow-x: hidden;
  position: relative;
  // min-height: 800px;
}

.slogan-container h1 {
  position: absolute;
  // padding-left: 50px;
  font-size: 30px;
  font-weight: 700;
  // top: 40px;
  // left: 40px;
  // margin-bottom: 50px;
  z-index: 5;
}

.slogan-container img {
  position: absolute;
  top: -112px;
  z-index: 2;
  // min-height: 800px;
}
