$text_color: #3a3f41;

// Home
$img_bg_color: rgba(0, 0, 0, 0.8);
$search_button_border_color: #cbcbcb;

// Login
$login_bg_color: #000;
$login_dashboard_bg_color: #ffff;
$remember_password_color: rgba(135, 135, 135, 0.8);

// Input and Select
$input_border_color: #cbcbcb;
$input_text_color: rgba(58, 63, 65, 0.7);
$place_holder_text_color: #bebebe;
$blocked_input_bg_color: rgba(0, 0, 0, 0.05);
$blocked_input_text_color: rgba(0, 0, 0, 0.4);

// Header
$header_bg_color: #0098da;
$header_text_color: #ffffff;

// Dashboard
// $dasboard_bg_color: grey;
$dasboard_bg_color: #ffffff;

// Tables
$table_header_bg_color: #6e849e;
$odd_bg_color: #eaeaea;
$even_bg_color: #f5f5f5;
$table_font_color: #3a3f41;
$table_blocked_text_color: #878787;
$table_heder_text_color: #ffffff;
$table_td_boder_color: #bebebe;
$font: "Roboto Condensed", sans-serif;
$pagination_a_color: #47ccde;

// Error alertl
$error_alert_color: #ee3232;

//Menu
$menu-bg-color: #0098da;
$menu_shadow_color: #0174ac;
$menu_text_color: #ffffff;

$menu_hover_option_color: #0076b9;

// Register
$register_bg_color: #000000;
$register_text_color: #ffffff;

// Usuarios
$call_to_action_text_color: #18a0fb;

// Login Button
$login_button_bg_color: #0098da;
$login_button_bg_hover_color: rgba(0, 152, 218, 0.8);
$login_button_text_color: #fff;

// Blocked Button
$blocked_button_bg_color: rgba(0, 152, 218, 0.3);

// Edit Button
$edit_button_bg_olor: #59ba74;
$edit_button_text_color: #fff;

//Date Picker
$date_picker_border: #cbcbcb;

// Dropzone
$drop_zone_border_color: #eeeeee;
$drop_zone_bg_color: rgba(237, 237, 237, 0.5);

// Accepted Files
$accepted_files_text_color: #3a3f41;

// Load Spinner
$load_message_text_color: #7f7f7f;

// Alert
$alert_color: #ff0000;

// Check
$check_color: #74C277;