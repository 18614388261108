@import "../../../variables/variables.scss";

/* Dynamic tables */

#users-table {
  td:nth-child(1) {
    width: 20%;
  }

  td:nth-child(2),
  td:nth-child(3) {
    width: 20%;
  }

  td:nth-child(4) {
    width: 25%;
  }
  td:nth-child(5) {
    width: 15%;
  }
}

.users-table {
  text-align: center;
  font-family: $font;
  border-collapse: collapse;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 12px;
}

.users-table th:first-child {
  border-top-left-radius: 12px;
}

.users-table th:last-child {
  border-top-right-radius: 12px;
}

.users-table th {
  background-color: $table_header_bg_color;
  color: $table_heder_text_color;
  padding: 10px 12px;
  text-align: center;
}

.users-table td {
  padding: 8px 12px;
  text-align: left;
  border: 1px solid $table_td_boder_color;
}

.users-table td > h6,
.users-table th > h6 {
  color: $text_color;
  margin: 0px;
}

.users-table tr:nth-child(even) {
  background-color: $even_bg_color;
}

.users-table tr:nth-child(odd) {
  background-color: $odd_bg_color;
}

.edit-button {
  background-color: $edit_button_bg_olor;
  color: $edit_button_text_color;
  transition: all 300ms;
}

.edit-button:hover {
  opacity: 0.8;
  cursor: pointer;
}

// Pagination

.pagination {
  margin: 36px 0px;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: $table_header_bg_color;
  border-color: $table_header_bg_color;
  color: $table_heder_text_color !important;
}
.pagination > li > a {
  border: 1px solid $table_header_bg_color;
  padding: 5px 10px;
  outline: none;
  font-family: $font;
  color: $table_font_color;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: $table_header_bg_color;
  border-color: $table_header_bg_color;
  outline: none;
  color: $table_font_color;
}
.pagination > li > a,
.pagination > li > span {
  font-family: $font;
  color: $pagination_a_color;
  background-color: $even_bg_color;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

// Bonds Table
#bonds-table td:nth-child(6) h6 {
  text-align: right;
}

#bonds-table {
  td:nth-child(1) {
    width: 10%;
    text-align: center;
  }
  td:nth-child(2),
  td:nth-child(3) {
    width: 15%;
    text-align: center;
  }
  td:nth-child(4) {
    width: 10%;
    text-align: center;
  }

  td:nth-child(5) {
    width: 2.5%;
    text-align: center;
  }

  // td:nth-child(5) {
  //   width: 11%;
  //   text-align: center;
  // }
  // td:nth-child(6) {
  //   width: 1%;
  //   text-align: center;
  // }
}

.table-action-button-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-evenly;
}

.no-font-awesome-element {
  height: 26px;
  width: 28px;
}

// Comments table
.comments-table-container {
  width: 100%;
  min-height: 75vh;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: unset;
}

#comments-table {
  width: 99%;
  td:nth-child(1) {
    width: 15%;
    text-align: center;
  }
  td:nth-child(2) {
    width: 50%;
    text-align: left;
  }
  td:nth-child(3) {
    width: 5%;
    text-align: center;
  }
}

// Bonds Gov Table
#gov-bonds-table td:nth-child(7) h6 {
  text-align: right;
}

#gov-bonds-table
  td:nth-child(1)
  h6 .text-red{
  color: red !important;
  font-weight: bold;
}

#gov-bonds-table
  td:nth-child(2)
  h6 .text-red{
  color: red !important;
  font-weight: bold;
}

#gov-bonds-table
  td:nth-child(3)
  h6 .text-red{
  color: red !important;
  font-weight: bold;
}

#gov-bonds-table
  td:nth-child(4)
  h6 .text-red{
  color: red !important;
  font-weight: bold;
}

#gov-bonds-table
  td:nth-child(5)
  h6 .text-red{
  color: red !important;
  font-weight: bold;
}

#gov-bonds-table 
  td:nth-child(6) 
  h6 .text-red{
  color: red !important;
  font-weight: bold;
}

#gov-bonds-table td:nth-child(7) h6 .text-red{
  text-align: right;
  color: red !important;
  font-weight: bold;
}

#gov-bonds-table {
  td:nth-child(1),
  td:nth-child(2),
  td:nth-child(3) {
    text-align: center;
  }
}

// #gov-bonds-table {
//   td:nth-child(1){
//     width: 5%;
//     text-align: center;
//   }
//   td:nth-child(2) {
//     width: 9%;
//     text-align: center;
//   }
//   td:nth-child(3) {
//     width: 13%;
//   }
//   td:nth-child(4) {
//     width: 10%;
//     text-align: center;
//   }
//   td:nth-child(5) {
//     width: 5%;
//     text-align: center;
//   }
//   td:nth-child(6) {
//     width: 5%;
//   }
// }

.img-button-container {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.img-button {
  background-color: $edit_button_bg_olor;
  border-radius: 6px;
  padding: 0px 28px;
  height: 40px;
  min-width: 40px;
  width: 60px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  transition: all 300ms;
}

.img-button:hover {
  cursor: pointer;
}

.img-button img {
  height: 20px;
}

.font-button {
  border: none;
  color: $table_header_bg_color;
  background: none;
}

// BondDataTable

.bond-data-table-container {
  display: flex;
  flex-flow: column;
  border: 1px solid $table_header_bg_color;
  border-radius: 10px;
  width: 100%;
}

.bond-data-table-container > div {
  display: flex;
  flex-flow: row;
}

.bond-data-table-container > div:nth-child(even) {
  border-top: 1px solid $table_header_bg_color;
  border-bottom: 1px solid $table_header_bg_color;
}

.bond-data-table-row {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  // width: 40%;
  padding: 6px 12px;
}

.table-row-60 {
  width: 60%;
}

.table-row-40 {
  width: 40%;
}

.table-row-30 {
  width: 30%;
}

.bond-data-table-row {
  h6:nth-child(1) {
    color: $text_color;
  }
  h6:nth-child(2) {
    color: $table_blocked_text_color;
  }
}

#general-data-title {
  margin-bottom: 8px;
}

.bond-number-container {
  display: flex;
  flex-flow: row;
}

.check-square {
  color: $check_color;
  margin-right: 12px;
  font-size: 18px;
}