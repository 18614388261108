@import "../../../variables/variables.scss";

#consult-bond-by-number-button {
  margin-top: 24px;
}

#save-audit-button {
  width: 100%;
  margin-top: 12px;
}

.delete-button {
  background-color: $alert_color;
  color: $edit_button_text_color;
  width: 180px;
}

.delete-button:hover {
  cursor: pointer;
}

.cancel-button {
  border: 2px solid $login_button_bg_hover_color;
  width: 180px;
}

.cancel-button:hover {
  cursor: pointer;
}
