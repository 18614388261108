@import "../../../variables//variables.scss";

.bond-comment-container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  // display: flex;
  // flex-flow: column;
  // align-items: center;
  // justify-content: center;
}

.bond-comment-container > div {
  height: 100%;
}

.bond-comment-sidebar-container {
  height: 100%;
  padding: 12px 24px !important;
  padding-right: 36px !important;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  background-color: $login_dashboard_bg_color;
  z-index: 100000;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
}

#bond-comment-sidebar {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  margin: 12px 0px;
}

#bond-comment-sidebar:hover {
  cursor: pointer;
}

.bond-comment-sidebar-container > h6 {
  color: $table_font_color;
  font-size: 18px;
  margin: 12px 0px;
}

#bond-comment-sidebar h6 {
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 12px;
}

#bond-comment-input {
  margin-top: 12px;
  height: 200px;
}

.title-comment {
  font-weight: bold;
}
