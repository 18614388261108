@import "../../variables/variables.scss";

.add-documment-container {
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
}

#registro-login-button {
  margin-top: 20px;
}

#registro-slogan-container h1 {
  top: 0px;
  left: 50px;
}

.register-input-date-container {
  margin-bottom: 12px;
}

.register-input-date-container > div {
  display: flex;
  flex-flow: row;
}

.react-datepicker-wrapper {
  width: 90% !important;
}

.register-input-date-mb {
  margin-bottom: 0px;
}

.react-datepicker__input-container input {
  width: 100%;
  border-radius: 5px;
  border: 2px solid $date_picker_border;
  height: 40px;
  padding-left: 12px;
  padding-bottom: 0px;
  font-weight: 700;
  color: rgba(58, 63, 65, 0.7);
  font-family: "Roboto Condensed", sans-serif;
}

.register-input-date-error {
  width: 100%;
}

.accepted-files-conainer {
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: unset;
  display: flex;
  flex-flow: column;
  align-items: center;
}

@media only screen and (max-width: 992px) {
  .disapear-register {
    display: none;
  }
}
