@import "../../../variables/variables.scss";

.form-error-ok {
  height: 25px;
  padding-top: 2px;
}

.form-error-alert {
  height: 25px;
  padding-top: 2px;
  padding-left: 5px;
  color: $error_alert_color;
  font-size: 14px;
  text-align: left;
}
