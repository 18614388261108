@import "../../../variables/variables.scss";

.dropzone-container > div:nth-child(1) {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  border: 2px dashed $drop_zone_border_color;
  background-color: $drop_zone_bg_color;
  border-radius: 2px;
  width: 100%;
  transition: all 300ms;
}
.dropzone-container > div:nth-child(1):hover {
  cursor: pointer;
}

.dropzone-container > div:nth-child(1) {
  height: 150px;
}

.dropzone-container > div:nth-child(1) > div {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.dropzone-container > div > div > h6 {
  margin-bottom: 0px;
  color: $input_text_color;
}

.load-file-button {
  border: 2px solid $login_button_bg_color;
}

.load-file-button h6 {
  color: $login_button_bg_color;
  margin-bottom: 0px;
}
