@import "../../variables/variables.scss";

.home-container {
  display: flex;
  flex-flow: row;
  margin: 0px !important;
  // justify-content: space-between;
}

.home-container > div {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: 100%;
}

.home-container > div:nth-child(1) {
  padding: 20px 60px;
}

.finanzas-search-container {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;

  div:nth-child(1) {
    display: flex;
    flex-flow: column;
    width: 250px;
    margin-right: 24px;
  }

  label {
    text-align: left;
  }
}

.home-container > {
  div:nth-child(1) h1 {
    margin: 20px 0px;
  }

  div:nth-child(2) {
    // width: 500px;
    background-color: $img_bg_color;
    position: relative;
    min-height: 90vh;
    max-height: 850px;
    overflow: hidden;
  }
}

#home-slogan-container img {
  position: absolute;
  width: 100%;
  top: 10px;
  left: 0px;
  opacity: 0.7;
}

/* Search Button */
.search-button {
  border: 2px solid $search_button_border_color;
  color: $search_button_border_color;
}

#home-slogan-container {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

#home-slogan-container h1 {
  top: 30px;
  left: 50px;
}

#home-slogan-container img {
  top: 0px;
}

@media only screen and (max-width: 786px) {
  #home-slogan-container {
    display: none;
  }
}
