@import "../../../variables/variables.scss";

// Files
.accepted-files-container {
  width: 95%;
  border-radius: 5px;
  border: 2px solid $input_border_color;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  margin: 6px 0px;
  padding: 6px 12px;
}

.accepted-files-container {
  div {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  }
  img:nth-child(1) {
    height: 35px;
    margin: 0px 12px;
  }
  h6 {
    margin-bottom: 0px;
  }
  img:nth-child(2) {
    height: 25px;
  }
  img:nth-child(2):hover {
    cursor: pointer;
  }
}

// DownloadedFiles

.accepted-files-list-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  height: 30vh;
  overflow-y: auto;
  overflow-x: unset;
}

.accepted-files-container {
  transition: all 300ms;
}

.accepted-files-container:hover {
  transform: scale(1.025, 1.025);
  cursor: pointer;
}

.loading-files-spinner-conainer {
  height: 120px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.loading-files-spinner-conainer h6 {
  margin-top: 6px;
}

#downloaded-files {
  width: 95%;
  background-color: $blocked_input_bg_color;
}

#no-files-avaliable-message {
  height: 30px;
  margin-top: 24px;
}

// DeleteFileModal

.delete-bond-document-modal {
  background-color: $login_dashboard_bg_color;
  width: 600px;
  border-radius: 20px;
  padding: 36px 12px;
}
.delete-bond-document-modal h1 {
  text-align: center;
  font-size: 24px;
}

.delete-bond-document-modal > h6 {
  margin-top: 24px;
  b {
    color: $alert_color;
  }
}

.remove-bond-document-button-container {
  margin-top: 70px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-evenly;
}
