@import "../../../variables/variables.scss";

.header {
  background-color: $header_bg_color;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
}

.header div {
  color: $header_text_color;
  width: 150px;
  display: flex;
  flex-flow: row;
  height: 25px;
  align-items: center;
  justify-content: space-evenly;
}

.header div:hover {
  cursor: pointer;
}

.header div h6 {
  margin: 0px;
}
